<script lang="ts">
  import { viewport } from "~/stores/viewport";

  export let ecsWorld;

  $: if ($viewport) {
    // CSS3D elements go "behind" the WebGL canvas
    ecsWorld.cssPresentation.setViewport($viewport);
    ecsWorld.cssPresentation.renderer.domElement.style.zIndex = "0";

    // WebGL canvas goes "on top" of CSS3D HTML elements
    ecsWorld.presentation.setViewport($viewport);
    ecsWorld.presentation.renderer.domElement.style.zIndex = "1";

    // HTML2D elements go "above" the WebGL canvas
    ecsWorld.htmlPresentation.setViewport($viewport);
    ecsWorld.htmlPresentation.domElement.style.zIndex = "2";
  }
</script>

<!-- svelte-ignore component-name-lowercase -->
<viewport bind:this={$viewport}>
  <!-- The CSS3DRenderer container div goes here -->
  <!-- The WebGLRenderer container canvas goes here -->
  <!-- The Html2D container div goes here -->
</viewport>

<style>
  viewport {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
  }
</style>
